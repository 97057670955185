<template>
  <div class="realEstatePanel">
    <div class="mt-2">
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon icon="SendIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ msg('New Ticket') }}
        </h4>
      </div>


      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <b-col cols="12">
          <b-form-group :label="msg('Client')">
            <client-picker v-model="clientId"/>
          </b-form-group>
        </b-col>

        <b-col v-if="clientId!=null && contractList!=null && contractList.length>0" cols="12">
          <b-form-group :label="msg('Contract')">
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="contractId"
                :options="manipulatedContracts"
                class="w-100"
                label="value"
                :reduce="(contract) => contract.id"

            />
          </b-form-group>
        </b-col>

        <b-col v-if="clientId!=null && contractList!=null && contractList.length>0 && contractId!=null" cols="12">
          <b-form-group :label="msg('Note')">

            <b-form-textarea
                id="event-description"
                v-model="ticket.reason"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="clientId!=null && contractList!=null && contractList.length>0 && contractId!=null && ticket.reason!=null&& ticket.reason!==''"  class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="createTicket">
          {{ msg('Save') }}
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import RealEstatePicker from "@/components/components/picker/realEstatesPicker.vue";
import ClientPicker from "@/components/components/picker/clientPicker.vue";
import {msg} from "@babel/core/lib/config/validation/option-assertions";

export default {
  name: 'ticketPanel',
  components: {ClientPicker},
  data() {
    return {
      loaded: false,
      contractId: null,
      ticket: {},
      clientId: null,
      contractList: [],
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  watch: {
    clientId: {
      immediate: true,
      handler() {
        this.loadContracts()
      }
    }
  },

  computed: {
    ...mapGetters('data', ['getAllBranches']),
    manipulatedContracts() {
      return this.contractList
          .filter((contract) => contract.propertyAddress) // Exclude contracts with null propertyAddress
          .map((contract) => ({
            id: contract.id,
            value: `${contract.propertyAddress} - ${this.formatDate(contract.bnbStyleRent?.start)} - ${this.formatDate(contract.bnbStyleRent?.start)}`,
          }));
    },

    allBranches() {
      return Object.values(this.getAllBranches)
    },
  },
  created() {
    let $this = this;

    this.$root.$on('crm::submitTicket', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitTicket', this.listeners.submit)
  },
  methods: {
    msg,
    ...mapActions('ticket', ['getAllTickets', 'copySuccess', 'addTicket']),
    ...mapActions('realEstateContracts', ['getAllByClients']),

    formatDate(date) {
      return date ? this.$moment(date, "YYYY-MM-DD").format("DD.MM.YYYY") : "No date";
    },
    loadContracts() {
      let $this = this
      this.getAllByClients(this.clientId)
          .then(
              (ans) => {
                $this.contractList = ans["Contracts"]
              }
          )
    },

    createTicket() {
      let $this = this

      this.addTicket({contractId: this.contractId, ticket: this.ticket})
          .then(
              (id) => {

                $this.$emit('submitted', {status: true, server: id, id})

              },
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },

    validate(field) {
      return this.$v.ticket[field] != null && this.$v.ticket[field].$error === true
    }
    ,
    submit() {
      return this.createTicket()
    },

  },

}
</script>
<style scoped>

.dark-layout #realEstate-description {
  background-color: rgba(255, 255, 255, 0.66);
  color: #000000;
}

.quill-container #realEstate-description {

  min-height: 400px;
}
</style>


